<dpa-common-menu
  [defaultMenuItemRoute]="defaultWorkspaceMenuItemRoute$ | async"
  [menuItems]="rootMenuItems$ | async"
></dpa-common-menu>
<ng-container *ngLet="showDashboardNavigationMenu$ | async as showDashboardNavigationMenu">
  @if (showDashboardNavigationMenu && (isUEMEnabled$ | async)) {
    <dpa-common-menu
      [menuItems]="uemMenuItems$ | async"
      [navGroup]="NavigationMenuGroup.WORKSPACE_UEM"
      [navGroupHeading]="'NAVIGATION.UEM' | translate"
      navGroupIconName="nodes"
    ></dpa-common-menu>
  }
  @if (isHorizonEnabled$ | async) {
    <dpa-common-menu
      [menuItems]="horizonMenuItems$ | async"
      [navGroup]="NavigationMenuGroup.WORKSPACE_VIRTUAL_APPS_DESKTOP"
      [navGroupHeading]="'NAVIGATION.VIRTUAL_APPS_DESKTOPS' | translate"
      navGroupIconName="nodes"
    ></dpa-common-menu>
  }
  @if (showDeemMenu$ | async) {
    <dpa-common-menu
      [menuItems]="deemMenuItems$ | async"
      [navGroup]="NavigationMenuGroup.WORKSPACE_EXPERIENCE_MANAGEMENT"
      [navGroupHeading]="'NAVIGATION.INTELLIGENCE_HEADER.DEEM' | translate"
      navGroupIconName="nodes"
    ></dpa-common-menu>
  }
  @if (showDashboardNavigationMenu && (showWorkspaceSecurityMenu$ | async)) {
    <dpa-common-menu
      [menuItems]="workspaceSecurityMenuItems$ | async"
      [navGroup]="NavigationMenuGroup.WORKSPACE_WORKSPACE_SECURITY"
      [navGroupHeading]="'NAVIGATION.WORKSPACE_SECURITY' | translate"
      navGroupIconName="nodes"
    ></dpa-common-menu>
  }
</ng-container>
@if (isUserBookmarksEnabled$ | async) {
  <dpa-bookmarks-menu></dpa-bookmarks-menu>
}
@if (isUemV2DashboardToggleEnabledInFF$ | async) {
  <dpa-standard-dashboard-uem-v2-toggle></dpa-standard-dashboard-uem-v2-toggle>
}

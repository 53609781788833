<form
  clrForm
  [formGroup]="requestCspAccountForm"
  class="p-x0"
>
  @if (showTitle) {
    <span class="request-cloud-account-heading">
      {{ 'BROWNFIELD_NOTIFICATION.REQUEST_ACCOUNT_HEADING' | translate }}
    </span>
  }
  <div [ngClass]="{ 'clr-row clr-justify-content-center pv-x4': showTitle }">
    @if (showTitle) {
      <div class="clr-col-4 csp-account-request-img"></div>
    }
    <div [ngClass]="{ 'clr-col-8 request-cloud-account-details': showTitle }">
      <span>
        {{ 'BROWNFIELD_NOTIFICATION.REQUEST_ACCOUNT_DESCRIPTION' | translate }}
      </span>
      <div class="pt-x4">
        <div
          [class.clr-error]="isEmailInvalid || !isCspAccountWithEmailNotExists"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper mb-x2">
            <input
              dpaAutoFocus
              [formControlName]="'email'"
              [placeholder]="'BROWNFIELD_NOTIFICATION.ENTER_EMAIL' | translate"
              [readOnly]="!isCspAccountWithEmailNotExists"
              name="email"
              type="email"
              class="clr-input email-input"
            />
            <cds-icon
              class="clr-validate-icon"
              shape="exclamation-circle"
            ></cds-icon>
          </div>
          @if (isEmailInvalid) {
            <span class="clr-subtext">
              {{
                (email?.errors?.required
                  ? 'BROWNFIELD_NOTIFICATION.EMAIL_REQUIRED_ERROR_MSG'
                  : 'BROWNFIELD_NOTIFICATION.EMAIL_FORMAT_ERROR_MSG'
                ) | translate
              }}
            </span>
          }
          @if (!isCspAccountWithEmailNotExists) {
            <span class="clr-subtext">
              {{ 'BROWNFIELD_NOTIFICATION.CLOUD_ACCOUNT_EXISTS_MSG' | translate }}
            </span>
          }
        </div>
        @if (showTitle) {
          <button
            [clrLoading]="(isRequestForCspAccountInProgress$ | async) ? CLR_LOADING_STATE.LOADING : CLR_LOADING_STATE.DEFAULT"
            [disabled]="isSubmitRequestForCspAccountButtonDisabled()"
            (click)="submitRequestForCspAccount()"
            class="btn btn-primary"
          >
            {{ 'BROWNFIELD_NOTIFICATION.REQUEST_ACCOUNT' | translate }}
          </button>
        }
        @if (!isCspAccountWithEmailNotExists) {
          <button
            (click)="continueWithExistingAccount()"
            class="btn btn-primary"
          >
            {{ 'COMMON_ACTIONS.CONTINUE' | translate }}
          </button>
        }
      </div>
    </div>
  </div>
  @if (!showTitle) {
    <button
      [clrLoading]="(isRequestForCspAccountInProgress$ | async) ? CLR_LOADING_STATE.LOADING : CLR_LOADING_STATE.DEFAULT"
      [disabled]="isSubmitRequestForCspAccountButtonDisabled()"
      (click)="submitRequestForCspAccount()"
      class="btn btn-primary request-account"
    >
      {{ 'BROWNFIELD_NOTIFICATION.REQUEST_ACCOUNT' | translate }}
    </button>
  }
</form>

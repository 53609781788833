@if (showSearchField) {
  <dpa-global-search
    [placeholder]="'GLOBAL_SEARCH.PLACEHOLDER' | translate"
    (selectItem)="onSelectItem($event)"
    (searchChange)="onSearch($event)"
    (categoryChange)="onCategoryChange($event)"
  >
    @if (visibleCategories.has(GlobalSearchCategory.APP) && stateByCategory[GlobalSearchCategory.APP]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [minQueryLength]="state.minQueryLength"
        [searchCategory]="GlobalSearchCategory.APP"
        [searchCategoryLabel]="'COMMON_MESSAGES.APPS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div
              [ngClass]="item.platform | platformIcon"
              class="dpa-icon platform-icon search-item-icon"
            ></div>
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.nameLabel }}
              </div>
              <div class="search-item-content-footer">
                <!--
                Possible values for appType string:
                APP_DETAILS.TYPE.DESKTOP
                APP_DETAILS.TYPE.INTERNAL
                APP_DETAILS.TYPE.PUBLIC
                APP_DETAILS.TYPE.WEB
                APP_DETAILS.TYPE.PURCHASED
                APP_DETAILS.TYPE.UNMANAGED
                APP_DETAILS.TYPE.UNKNOWN
                -->
                <span>
                  {{ 'COMMON_MESSAGES.TYPE' | translate }}:
                  {{ 'APP_DETAILS.TYPE.' + item.getAppType() | translate }}
                </span>
                @if (item.packageIdLabel) {
                  <span class="large-dot">&middot;</span>
                  {{ 'APTELIGENT.PACKAGE_ID' | translate: { packageId: item.packageIdLabel } }}
                }
                @if (item.isProductivityApp) {
                  <span class="large-dot">&middot;</span>
                  {{ 'GLOBAL_SEARCH.MOBILE_PRODUCTIVITY_APPS' | translate }}
                } @else {
                  @if (item.apteligentAppId) {
                    <span class="large-dot">&middot;</span>
                    {{ 'GLOBAL_SEARCH.APTELIGENT_ENABLED' | translate }}
                  }
                }
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.APP_APTELIGENT) && stateByCategory[GlobalSearchCategory.APP_APTELIGENT]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [searchCategory]="GlobalSearchCategory.APP_APTELIGENT"
        [searchCategoryLabel]="'COMMON_MESSAGES.APPS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div
              [ngClass]="item.platform ? item.platform.toLowerCase() : 'webapp'"
              class="dpa-icon platform-icon search-item-icon"
            ></div>
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                @if (item.packageId) {
                  <span class="large-dot">&middot;</span>
                  {{ 'APTELIGENT.PACKAGE_ID' | translate: { packageId: item.packageId } }}
                }
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.AUTOMATION) && stateByCategory[GlobalSearchCategory.AUTOMATION]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [searchCategory]="GlobalSearchCategory.AUTOMATION"
        [searchCategoryLabel]="'NAVIGATION.FREESTYLE' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{ 'COMMON_MESSAGES.CATEGORY' | translate }}:
                  {{ state.categoryLabelsByName[item.targetEntity] }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>{{ 'AUTOMATION_ACTIONS.STATUS' | translate }}:</span>
                <span [ngClass]="item.active ? 'text-success' : 'text-danger'">
                  {{ (item.active ? 'AUTOMATION_ACTIONS.ENABLED' : 'AUTOMATION_ACTIONS.DISABLED') | translate }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.DASHBOARD) && stateByCategory[GlobalSearchCategory.DASHBOARD]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [searchCategory]="GlobalSearchCategory.DASHBOARD"
        [searchCategoryLabel]="'NAVIGATION.DASHBOARDS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{
                    'DASHBOARD_ACTIONS.GLOBAL_COUNT_TOTAL_WIDGETS' | translate: { count: item.widgetSummary?.total || 0 | number: '1.0-0' }
                  }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>
                  {{ 'DASHBOARD_ACTIONS.LAST_WIDGET_ADDED' | translate }}
                  <span>
                    @if (item.widgetSummary?.mostRecent?.createdAt) {
                      {{ item.widgetSummary.mostRecent.createdAt | formattedDate: DateTimeFormat.MOMENT_LONG_DATE_FORMAT }}
                    } @else {
                      {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
                    }
                  </span>
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.DEVICE) && stateByCategory[GlobalSearchCategory.DEVICE]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [minQueryLength]="state.minQueryLength"
        [searchCategory]="GlobalSearchCategory.DEVICE"
        [searchCategoryLabel]="'COMMON_MESSAGES.DEVICES' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div
              [ngClass]="item.platform | platformIcon"
              class="dpa-icon platform-icon search-item-icon"
            ></div>
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate: { name: item.platform } }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>
                  {{ 'COMMON_MESSAGES.MODEL_NAME' | translate: { name: item.model } }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.OS) && stateByCategory[GlobalSearchCategory.OS]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [searchCategory]="GlobalSearchCategory.OS"
        [searchCategoryLabel]="'COMMON_MESSAGES.OS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div
              [ngClass]="item.name | platformIcon"
              class="dpa-icon platform-icon search-item-icon"
            ></div>
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{ 'DASHBOARD_ACTIONS.COUNT_VERSIONS' | translate: { count: item.versionsCount | number } }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>
                  {{ 'DASHBOARD_ACTIONS.COUNT_DEVICES' | translate: { count: item.devicesCount | number } }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.REPORT) && stateByCategory[GlobalSearchCategory.REPORT]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [searchCategory]="GlobalSearchCategory.REPORT"
        [searchCategoryLabel]="'NAVIGATION.REPORTS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{ 'COMMON_MESSAGES.CATEGORY' | translate }}:
                  {{ item.entityLabel }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>
                  {{ 'COMMON_MESSAGES.AVAILABLE_DOWNLOADS' | translate }}:
                  {{ item.totalDownloads }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.USER) && stateByCategory[GlobalSearchCategory.USER]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [minQueryLength]="state.minQueryLength"
        [searchCategory]="GlobalSearchCategory.USER"
        [searchCategoryLabel]="'NAVIGATION.USERS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.userName }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{ item.firstName }}
                  {{ item.lastName }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>
                  {{ item.email }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
    @if (visibleCategories.has(GlobalSearchCategory.WIDGET) && stateByCategory[GlobalSearchCategory.WIDGET]; as state) {
      <dpa-global-search-results-container
        [keyBy]="state.keyBy"
        [isLoading]="state.isLoading$ | async"
        [items]="state.items$ | async"
        [subgroupConfig]="state.subgroupConfig$ | async"
        [searchCategory]="GlobalSearchCategory.WIDGET"
        [searchCategoryLabel]="'COMMON_MESSAGES.WIDGETS' | translate"
      >
        <ng-template let-item>
          <div class="search-item-container">
            <cds-icon
              [attr.shape]="item.chartType | dpaIconName: '' : '-chart'"
              class="dpa-icon chart-type search-item-icon"
            ></cds-icon>
            <div class="search-item-content">
              <div class="search-item-content-title">
                {{ item.name }}
              </div>
              <div class="search-item-content-footer">
                <span>
                  {{ 'COMMON_MESSAGES.DASHBOARD' | translate }}:
                  {{ item.dashboardName }}
                </span>
                <span class="large-dot">&middot;</span>
                <span>
                  {{ 'COMMON_MESSAGES.CATEGORY' | translate }}:
                  {{ state.categoryLabelsByName[item.trend.trendDefinition.entity] }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </dpa-global-search-results-container>
    }
  </dpa-global-search>
}
